import { Component, OnInit } from '@angular/core';
import { ThemeType } from './common/common';
import { RemoteService } from './service/service.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Get Token', url: '/get-token', icon: 'create' },
    { title: 'Login', url: '/login', icon: 'log-in' },
    { title: 'About', url: '/about', icon: 'information-circle' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private remoteSvc: RemoteService) {
    this.checkForTokenListMenu();
  }

  ngOnInit() {
    this.remoteSvc.onMenuChange.subscribe((e) => {
      this.checkForTokenListMenu();
    });
    this.remoteSvc.createOnline$().subscribe((isOnline) => {
      if (isOnline === false) {
        this.remoteSvc.presentToast('You are not connected to internet. ', ThemeType.warning, 20000);
      }
    });
  }

  checkForTokenListMenu() {
    if (this.remoteSvc.userInfo) {
      if (!this.appPages.some((e) => e.url === '/token-list')) {
        this.appPages.push({
          title: 'Token List',
          url: '/token-list',
          icon: 'list',
        });
      }
    }
  }
}
