import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import {
  fromEvent,
  merge,
  Observable,
  Observer,
  Subject,
  throwError,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ThemeType } from '../common/common';

@Injectable({
  providedIn: 'root',
})
export class RemoteService {
  // delopmentID = '';
  serverURL = `https://script.google.com/macros/s/AKfycby5UokN5DwCTyBtr18tnVrQEWj1f6ynwM6w1ozhYPbTJxQ00Ww9A88rntM5jbxSe7pz/exec`; // dev
  newServerURL = 'http://192.168.1.5:5000';
  // serverURL = `https://script.google.com/macros/s/AKfycbxP_zRs4ZJZ0I005eec5R0yHCDsih4e4VlxZKwy9jXzuhTA-9Rtuljfd5hsQMOq620Q/exec`; // prod
  // serverURL = '/api';
  // private userInfo: any;

  onMenuChange = new Subject();

  get isNewUser() {
    return Boolean(window.localStorage.getItem('isNewUser'));
  }

  set isNewUser(data: boolean) {
    window.localStorage.setItem('isNewUser', JSON.stringify(data));
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get userInfo() {
    return JSON.parse(window.localStorage.getItem('userInfo'));
  }
  set userInfo(data) {
    window.localStorage.setItem('userInfo', JSON.stringify(data));
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get tokenHolder() {
    return JSON.parse(window.localStorage.getItem('tokenHolder'));
  }

  set tokenHolder(data) {
    window.localStorage.setItem('tokenHolder', JSON.stringify(data));
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private remoteService: HttpClient,
    private toastController: ToastController
  ) {
    // this.getUserFromStore();
  }

  getTokenList(date: string, loader: any) {
    loader.isLoading = true;
    const param = `getTokensList&date=${date}`;
    // return this.remoteService.get(`${newServerURL}?action=${param}`).pipe(
    return this.remoteService.get(`${this.newServerURL}?date=${date}`).pipe(
      catchError((err) => {
        loader.isLoading = false;
        return throwError(err);
      }),
      tap((d) => {
        loader.isLoading = false;
      })
    );
  }

  getOnlineStatus(adminID, loader) {
    loader.isLoading = true;
    // const param = `getDocStatus&docID=${adminID}`;
    return this.remoteService.get(`${this.newServerURL}/getDocStatus/${adminID}`).pipe(
      catchError((err) => {
        loader.isLoading = false;
        return throwError(err);
      }),
      tap((d) => {
        loader.isLoading = false;
      })
    );
  }

  doLogin(username, password, loader) {
    loader.isLoading = true;
    const params = {
      username,
      password,
    };
    // const url = `${this.newServerURL}/login`;
    return this.remoteService.post(`${this.newServerURL}/login`, params).pipe(
      catchError((err) => {
        loader.isLoading = false;
        return throwError(err);
      }),
      tap((d) => {
        loader.isLoading = false;
      })
    );
  }
  doLogInByGet(adminID, pwd, loader) {
    loader.isLoading = true;
    const queryParam = `login&adminID=${adminID}&pwd=${pwd}`;
    return this.remoteService
      .get(`${this.newServerURL}login?action=${queryParam}`)
      .pipe(
        catchError((err) => {
          loader.isLoading = false;
          return throwError(err);
        }),
        tap((d) => {
          loader.isLoading = false;
        })
      );
  }

  toggleOnlineStatus(adminID, loader) {
    loader.isLoading = true;
    const queryParam = `toggleDocStatus&docID=${adminID}`;
    // const params = {
    //   adminID,
    // };
    return this.remoteService
      .get(`${this.serverURL}?action=${queryParam}`)
      .pipe(
        catchError((err) => {
          loader.isLoading = false;
          return throwError(err);
        }),
        tap((d) => {
          loader.isLoading = false;
        })
      );
  }

  toggleAcceptingTokenStatus(adminID, loader) {
    loader.isLoading = true;
    const queryParam = `toggleAcceptingTokenStatus&docID=${adminID}`;
    return this.remoteService
      .get(`${this.serverURL}?action=${queryParam}`)
      .pipe(
        catchError((err) => {
          loader.isLoading = false;
          return throwError(err);
        }),
        tap((d) => {
          loader.isLoading = false;
        })
      );
  }

  changeTokenStatus(date: string, changeStatusAction: string, loader) {
    loader.isLoading = true;
    const queryParam = `changeTokenStatus&date=${date}&changeStatusAction=${changeStatusAction}`;
    return this.remoteService
      .get(`${this.serverURL}?action=${queryParam}`)
      .pipe(
        catchError((err) => {
          loader.isLoading = false;
          return throwError(err);
        }),
        tap((d) => {
          loader.isLoading = false;
        })
      );
  }

  addToken(
    name: string,
    date: string,
    timeSlot: string,
    docID: string,
    mobileNo: number,
    deviceModel: string,
    devicePlatform: string
  ) {
    const queryParam = `addToken&name=${name}&date=${date}&timeSlot=${timeSlot}
    &docID=${docID}&mobileNo=${mobileNo}&deviceModel=${deviceModel}&devicePlatform=${devicePlatform}`;
    const params = {
      name,
      date,
      docID,
    };
    return this.remoteService.get(`${this.serverURL}?action=${queryParam}`);
  }

  getCurrentTokenByDate(date: string, adminID, loader) {
    loader.isLoading = true;
    const queryParam = {
      date,
      adminID
    };
    return this.remoteService
      .post(`${this.newServerURL}/getLatestTokenIDByDate`, queryParam)
      .pipe(
        catchError((err) => {
          loader.isLoading = false;
          return throwError(err);
        }),
        tap((d) => {
          loader.isLoading = false;
        })
      );
  }

  createCurrentDate() {
    const d = new Date();
    const currDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    return currDate;
  }

  getDateFromUTC(date) {
    const d = new Date(date);
    const dd = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    return dd;
  }

  async presentToast(message, color?: string, duration = 3000) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'bottom',
      color: color ? color : 'dark',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }

  cancelAllTokenByDate(date: string, docID: string, loader) {
    loader.isLoading = true;
    const queryParam = `cancelAllTokenByDate&docID=${docID}&date=${date}`;
    return this.remoteService
      .get(`${this.serverURL}?action=${queryParam}`)
      .pipe(
        catchError((err) => {
          loader.isLoading = false;
          return throwError(err);
        }),
        tap((d) => {
          loader.isLoading = false;
        })
      );
  }

  cancelTokenByIDAndDate(
    date: string,
    tokenNumber: number,
    docID: string,
    loader
  ) {
    loader.isLoading = true;
    const queryParam = `cancelTokenByIDAndDate&docID=${docID}&date=${date}&tokenNumber=${tokenNumber}`;
    return this.remoteService
      .get(`${this.serverURL}?action=${queryParam}`)
      .pipe(
        catchError((err) => {
          loader.isLoading = false;
          return throwError(err);
        }),
        tap((d) => {
          loader.isLoading = false;
        })
      );
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  getCurrentTokenByTokenIDDate(tokenID: number, date: string, loader) {
    loader.isLoading = true;
    const queryParam = `getTokenByTokenIDAndDate&tokenID=${tokenID}&date=${date}`;
    return this.remoteService
      .get(`${this.serverURL}?action=${queryParam}`)
      .pipe(
        catchError((err) => {
          loader.isLoading = false;
          return throwError(err);
        }),
        tap((d) => {
          loader.isLoading = false;
        })
      );
  }
}
