import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TutorialGuard } from './auth/tutorial.gaurd';
import { RemoteService } from './service/service.service';

const routes: Routes = [
  {
    path: 'folder/:id',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'token-list',
    loadChildren: () =>
      import('./taken-list/taken-list.module').then(
        (m) => m.TakenLIstPageModule
      ),
  },
  {
    path: 'get-token',
    loadChildren: () =>
      import('./get-token/get-token.module').then((m) => m.GetTokenPageModule),
    canActivate: [TutorialGuard]
  },
  {
    path: 'create-token',
    loadChildren: () =>
      import('./create-token/create-token.module').then(
        (m) => m.CreateTokenPageModule
      ),
  },
  {
    path: 'guide',
    loadChildren: () => import('./guide/guide.module').then(m => m.GuidePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  { redirectTo: 'get-token', path: '**' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private remoteSvc: RemoteService) {
    const user = this.remoteSvc.userInfo;
    if (user) {
      routes.unshift({
        path: '',
        redirectTo: 'token-list',
        pathMatch: 'full',
      });
    }
    else {
      routes.unshift({
        path: '',
        redirectTo: 'get-token',
        pathMatch: 'full',
      });
    }
  }
}
