import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
interface IDeviceInfo {
  name: string;
  isVirtual: boolean;
  manufacturer: string; //"Google Inc."
  model: string; //"Windows NT 10.0"
  operatingSystem: string; // "windows"
  osVersion: string;//"Windows NT 10.0; Win64; x64"
  platform: string; //"web"
  webViewVersion: string; // "107.0.0.0"
}
@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  logDeviceInfo: DeviceInfo;
  constructor(
  ) {
    // this.getUserFromStore();
    if (!this.logDeviceInfo) {
      this.deviceInfo();
    }
  }

  private deviceInfo() {
    const divInfo = async () => {
      const info: DeviceInfo = await Device.getInfo();
      this.logDeviceInfo = info;
      // console.log(info);
    };
    divInfo().then((d) => {
      // this.logDeviceInfo = d;
    });
  }
}
