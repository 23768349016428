import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RemoteService } from '../service/service.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialGuard implements CanActivate {

  constructor(private router: Router, private remoteService: RemoteService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    const isComplete = this.remoteService.isNewUser;
    // const isComplete = false;
    if (!isComplete) {
      this.remoteService.isNewUser = true;
      this.router.navigateByUrl('/guide');
    }

    return isComplete;
  }
}
