export enum ThemeType {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  light = 'light',
  medium = 'medium',
  dark = 'dark',
}

export enum HQConfig {
  docID = 'demo2023',
  timeSlot = '(6pm to 8pm)',
}
