import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { RemoteService } from './service.service';
import { ThemeType } from '../common/common';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private remoteSvc: RemoteService) {}

  handleError(error: Error | HttpErrorResponse | any) {
    console.log('GlobalErrorHandlerService');
    console.error(error);
    if (error.name === 'TimeoutError' || error?.status === 504  ) {
      this.remoteSvc.presentToast('This might be a network issue.', ThemeType.danger, 0);
    }else{
      this.remoteSvc.presentToast('Something went wrong. Please connect to admin.', ThemeType.warning,0);
    }
  }
}
// Type of error.
// "HttpErrorResponse"
